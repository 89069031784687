import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  inject,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { IVerifyDeviceForm } from '@desquare/interfaces';
import {
  CreateLocationInput,
  DeviceOrientation,
  GetProfileForDeviceFormQuery,
  Maybe,
} from '@designage/gql';
import {
  CurrentUserService,
  DeviceDataService,
  ProfileSettingsService,
} from '@desquare/services';
import { ChannelApplication } from '@desquare/enums';
import { timeZonesNames } from '@vvo/tzdb';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadComponent } from '@desquare/components/common/src/typeahead/typeahead.component';
import { LocationTabSelectionComponent } from '@designage/app/location/location-tab-selection/location-tab-selection.component';
import { LoaderComponent } from '@desquare/components/common/src/loader/loader.component';
import { ChannelsStore } from '@desquare/stores';

enum LocationTabs {
  CREATE_NEW_LOCATION = 'CREATE_NEW_LOCATION',
  ADD_TO_EXISTING_LOCATION = 'ADD_TO_EXISTING_LOCATION',
}

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    NgbTooltip,
    NgbDropdownModule,
    TypeaheadComponent,
    LocationTabSelectionComponent,
    LoaderComponent,
    TypeaheadComponent,
  ],
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss'],
})
export class DeviceFormComponent implements OnInit {
  channelsStore = inject(ChannelsStore);

  @Output() submitted = new EventEmitter<IVerifyDeviceForm>();
  @Output() values = new EventEmitter<IVerifyDeviceForm>();
  @Output() valid = new EventEmitter<boolean>();
  @Output() pristine = new EventEmitter<boolean>();
  @Output() formInitialized = new EventEmitter<FormGroup>();

  @Input() areExtraButtonsHidden = false;
  @Input() profileId!: Maybe<string>;
  @Input() channelId!: Maybe<string>;
  @Input() isHideChannelSelection!: boolean;
  @Input() isHideNameInput!: boolean;
  @Input() deviceName!: Maybe<string>;
  @Input() country!: Maybe<string>;

  // @Input() isValidHashCode!: boolean
  @Input() isInvalidCode!: boolean;

  @Input() parentFormGroup?: FormGroup;

  // Location Form
  @Input() showSelectionOnly = false;
  @Input() locationId!: Maybe<string>;

  channels = this.channelsStore.channels;
  deviceForm!: FormGroup;
  profile!: GetProfileForDeviceFormQuery['profile'];
  minimumDimension = 500;
  nameRequired!: boolean;
  deviceUid!: string;
  orientations!: DeviceOrientation[];
  timezones!: string[];
  applications!: ChannelApplication[];
  noMatchedItemText = '';

  // Location Form
  locationForm!: FormGroup;
  locationInput!: Partial<CreateLocationInput>;
  isLocationFormValid!: boolean;
  currentTab!: LocationTabs;

  // Loader
  loading = false;
  loaderMessage = '';
  newTimeZone = '';

  format(value: number) {
    return value + '%';
  }

  constructor(
    private formBuilder: FormBuilder,
    private currentUserService: CurrentUserService,
    private deviceService: DeviceDataService,
    public profileSettingsService: ProfileSettingsService
  ) {}

  ngOnInit() {
    this.initVariables();
    this.initForm();
    this.setFormStates();
  }

  initForm() {
    const FORM_GROUP_NAME = 'device';

    this.deviceForm =
      (this.parentFormGroup?.controls[FORM_GROUP_NAME] as FormGroup) ??
      this.formBuilder.group({
        profileId: [this.profileId ?? undefined, Validators.required],
        name: [
          this.parentFormGroup?.controls.channel?.value.name ??
            this.deviceName ??
            null,
          Validators.required,
        ],
        channelId: [
          null,
          this.isHideChannelSelection ? null : Validators.required,
        ],
        verificationHash: [null, Validators.required],
        deviceUid: [''],
        orientation: [DeviceOrientation.Landscape],
        timeZone: [''],
        ntpServer: [this.deviceService.defaultNtpServer],
        volume: [0],
        application: [ChannelApplication.APPLET],
      });

    if (this.parentFormGroup) {
      console.log('initForm this.parentFormGroup', this.parentFormGroup);
      this.parentFormGroup.addControl(FORM_GROUP_NAME, this.deviceForm);
    }

    if (this.deviceName) {
      this.deviceForm.controls.name.markAsDirty();
    }
    console.log('initForm this.deviceForm', this.deviceForm);
  }

  setFormStates() {
    this.deviceForm.valueChanges.subscribe((values: IVerifyDeviceForm) => {
      this.valid.emit(this.deviceForm.valid);
      this.pristine.emit(this.deviceForm.pristine);
      this.values.emit(values);
    });
  }

  initVariables() {
    // this.isVerified = false;
    // this.isValidHashCode = false;
    this.nameRequired = false;
    this.deviceUid = '';
    this.orientations = [
      DeviceOrientation.Auto,
      DeviceOrientation.Landscape,
      DeviceOrientation.LandscapeFlipped,
      DeviceOrientation.Portrait,
      DeviceOrientation.PortraitFlipped,
    ];
    this.timezones = timeZonesNames;
    this.applications = [ChannelApplication.SMIL, ChannelApplication.APPLET];
  }

  // submit event can be any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit(event: any, values: IVerifyDeviceForm) {
    this.submitted.emit(values);
  }

  onKey(field: string) {
    if (field === 'name' && this.deviceForm.controls.name.value) {
      this.nameRequired = false;
    }
  }

  isSuperAdmin() {
    return this.currentUserService.isSuperAdmin;
  }

  updateOrientation(orientation: DeviceOrientation) {
    this.deviceForm.patchValue({ orientation });
  }

  updateApplication(application: ChannelApplication) {
    this.deviceForm.patchValue({ application });
  }
  updateTimezone(timeZone: string) {
    this.deviceForm.patchValue({ timeZone });
  }

  getProfileTimezone() {
    this.newTimeZone =
      this.profileSettingsService.getProfileTimezoneValue().timezone;
    console.log('this.newTimeZone', this.newTimeZone);
    this.updateTimezone(this.newTimeZone);
  }
}
